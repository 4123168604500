import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Box,
  Card,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  ArrowRight as LinkIcon,
} from '@mui/icons-material';

import { useSession, useMisc } from 'store';
import { trackers, postbackTypes, jobAttrs, jobAttrExcludeRegion } from 'consts';
import { callApi } from 'utils';
import { Popup } from 'components';

const StatCard = ({name, value}) => (
  <Card
    sx={{
      padding: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    <Box>
      <Typography variant="caption" component="div" gutterBottom sx={{mt: '-3px'}}>{name}</Typography>
      <Typography variant="h6" sx={{whiteSpace: 'nowrap'}}>
        {value === undefined ? 'N/A' : value}
      </Typography>
    </Box>
  </Card>
);

const DayCard = ({day, imps, dayparts, isPast}) => (
  <Card
    sx={{
      padding: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: isPast ? '#c4bfbfbf' : '#fff',
    }}
  >
    <Box>
      <Typography variant="caption" component="div" gutterBottom sx={{mt: '-3px', whiteSpace: 'nowrap'}}>
        {day}{dayparts && (<span style={{marginLeft: "8px"}}>{dayparts}</span>)}
      </Typography>
      <Typography variant="h6">
        {imps === undefined ? 'N/A' : imps}
      </Typography>
    </Box>
  </Card>
);

const Label = ({name}) => (
  <div style={{color: '#546e7a', textAlign: 'right', marginRight: '8px'}}>{name}:</div>
);

const indicators = [
  {
    name: 'Impressions',
    valid: (_) => true,
    value: (s) => s.imps || 0,
  }, {
    name: 'Clicks',
    valid: (_) => true,
    value: (s) => s.clicks || 0,
  }, {
    name: 'Landing Rate',
    valid: (s) => s.clicks > 0,
    value: (s) => (100*s.landing/s.clicks).toFixed(2) + '%',
  }, {
    name: 'CTR',
    valid: (s) => s.clicks > 0,
    value: (s) => (100*s.clicks/s.imps).toFixed(2) + '%',
  }, {
    name: 'Stay',
    valid: (s) => s.clicks > 0 && 100*s.landing > s.clicks, // landing rate > 1%
    value: (s) => Math.ceil(s.avg_stay) + 's',
  }, {
    name: 'Progress',
    valid: (_) => true,
    value: (s) => s.progress?.[1] ? s.progress.map(p => (100*p).toFixed(1) + '%').join(' / ') : '--',
  },
  ...['Jump2', 'Jump3'].map((name, i) => ({
    name,
    valid: (s) => s.page_cnt[i] > 0,
    value: (s) => (100*s.page_cnt[i]/s.landing).toFixed(1) + '%',
  })),
  ...trackers.map((name, i) => ({
    name,
    valid: (s) => s.tracker_cnt[i] > 0,  // && 100*s.landing > s.clicks
    value: (s) => (100*s.tracker_cnt[i]/s.landing).toFixed(1) + '% / ' + Math.ceil(s.tracker_stay[i]) + 's',
  })),
  // bounce rate for trackers
  ...trackers.map((name, i) => ({
    name: name + ' B/R',
    valid: (s) => s.tracker_bounce[i] > 0 && s.tracker_cnt[i] > 0,
    value: (s) => (100*s.tracker_bounce[i]/s.tracker_cnt[i]).toFixed(1) + '%',
  })),
];

function formatOsvPct(pct) {
  return Object.keys(pct).sort().map(osv => {
    const os = ({i: 'iOS', a: 'Android'})[osv[0]] || 'Unknown';
    return os + osv.substring(1) + ': ' + pct[osv] + '%';
  }).join(', ');
}

function formatUATpl(tpls) {
  const dat = {osv: '15', model: 'V2357A', build: 'UP1A.231005.007', brver: '120.0.6099.193'};
  const rep = (s) => Object.keys(dat).reduce((s, a) => s.replaceAll('__'+a+'__', dat[a]), s);
  return Object.entries(tpls).map(([os, ua]) => {
    os = ({ios: 'iOS', android: 'Android'})[os] || 'Unknown';
    return os + ': ' + rep(ua);
  }).join('\n');
}

const JobState = ({state, onClose}) => {
  const session = useSession((store) => store.session);
  const misc = useMisc((store) => store.data);
  const [cfg, setCfg] = useState(null);

  const {job, stats} = state || {};

  useEffect(() => {
    job && callApi(`/jobs/${job.id}/config`).then(res => setCfg(res.data)).catch(console.error);
  }, [job]);

  if (!job)
    return null;

  // build current running stats
  let runStats;
  if (stats) {
    runStats = indicators.filter(idc => idc.valid(stats)).map(idc => ({name: idc.name, value: idc.value(stats)}));
  }

  // build every day imps
  const startDate = dayjs(job.start_date), endDate = dayjs(job.end_date);
  const hasDayparts = Object.keys(job.dayparts).length > 0;
  const today = dayjs().format('YYYYMMDD');
  const schedule = [];
  for (let d = startDate; !d.isAfter(endDate, 'day'); d = d.add(1, 'day')) {
    const ymd = d.format('YYYYMMDD');
    schedule.push({
      day: d.format('MM-DD'),
      imps: isNaN(job.daily_imps[ymd]) ? job.daily_imps.default : job.daily_imps[ymd],
      dayparts: hasDayparts ? (job.dayparts[ymd] || job.dayparts.default || ['00:00','23:59']).join('~') : null,
      isPast: ymd < today,
    });
  }

  let devicePct = misc['device_filter'][job.device_filter];
  if (job.customize) {
    const s = job.customize.replace('_', ' ') + '%';
    devicePct = s.charAt(0).toUpperCase() + s.slice(1);
  }

  let attrs = jobAttrs.filter(a => a.val !== jobAttrExcludeRegion && (job.attrs&a.val) > 0).map(a => a.display).join(', ');

  return (
    <Popup
      open={Boolean(job)}
      onClose={onClose}
      title={`${job.id} - ${job.name}`}
    >
      {runStats && (
      <Grid sx={{mt: 0}} container spacing={2}>
        {runStats.map((stat, i) => (
        <Grid item lg={2} sm={4} xs={6} key={i}>
          <StatCard {...stat} />
        </Grid>
        ))}
      </Grid>
      )}

      <Typography variant="h6" sx={{mt: 2, pb: 1, borderBottom: "1px solid #ccc"}}>Job Schedule</Typography>
      <Grid sx={{mt: -1}} container spacing={2}>
        {schedule.map(day => (
        <Grid item lg={2} sm={4} xs={6} key={day.day}>
          <DayCard {...day} />
        </Grid>
        ))}
      </Grid>

      <Typography variant="h6" sx={{mt: 2, mb: 1, pb: 1, borderBottom: "1px solid #ccc"}}>Job Details</Typography>
      <Grid container spacing={1} wrap="wrap">
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Start/End Date"/></Grid>
          <Grid item xs>{job.start_date + ' ~ ' + job.end_date}</Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Expect CTR"/></Grid>
          <Grid item xs>{job.ctr}%</Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Overrun"/></Grid>
          <Grid item xs>{job.overrun}%</Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Traffic Curve"/></Grid>
          <Grid item xs>{misc['traffic_curve'][job.traffic_curve]}</Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Postback"/></Grid>
          <Grid item xs>{
            !job.postback ? 'No' :
              (job.postback === 'all' ? 'All' : job.postback.split(',').map(t => postbackTypes[t]).join(', '))
          }</Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Device Percent"/></Grid>
          <Grid item xs>{devicePct}</Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Landing Rate"/></Grid>
          <Grid item xs>{job.landing}%</Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="FreqCtrl ID"/></Grid>
          <Grid item xs>{job.fc_id || job.id}</Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Imp FreqCtrl"/></Grid>
          <Grid item xs>{job.fc_max}</Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Group ID"/></Grid>
          <Grid item xs>{job.parent || job.id}</Grid>
        </Grid>
        {session.role === 'admin' && (<>
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Organization"/></Grid>
          <Grid item xs>{misc['org'][job.org]}</Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Page Actions"/></Grid>
          <Grid item xs>{job.ant_cfg || 'Not Set'}</Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Last Updated"/></Grid>
          <Grid item xs>{dayjs(job.updated_at).format('YYYY-MM-DD HH:mm:ss')}</Grid>
        </Grid>
        </>)}
        {job.dest_url.startsWith('adx:') ? (
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Adx TagID"/></Grid>
          <Grid item xs>{job.dest_url.substring(4)}</Grid>
        </Grid>
        ) : (<>
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Landing Url"/></Grid>
          <Grid item xs={9} md={10}><Typography sx={{wordWrap: 'break-word'}}>{job.dest_url || '--'}</Typography></Grid>
        </Grid>
        {job.trackers?.imp && (
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Imp Trackers"/></Grid>
          <Grid item xs={9} md={10}>
            <List dense={true} sx={{p: 0}}>
            {job.trackers.imp.map((tkr, i) =>
              <ListItem key={i} sx={{p: 0}}>
                <ListItemIcon sx={{minWidth: 0}}><LinkIcon /></ListItemIcon>
                <ListItemText sx={{ml: 0, wordWrap: 'break-word'}} primary={tkr} />
              </ListItem>
            )}
            </List>
          </Grid>
        </Grid>
        )}
        {job.trackers?.clk && (
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Click Trackers"/></Grid>
          <Grid item xs={9} md={10}>
            <List dense={true} sx={{p: 0}}>
            {job.trackers.clk.map((tkr, i) =>
              <ListItem key={i} sx={{p: 0}}>
                <ListItemIcon sx={{minWidth: 0}}><LinkIcon /></ListItemIcon>
                <ListItemText sx={{ml: 0, wordWrap: 'break-word'}} primary={tkr} />
              </ListItem>
            )}
            </List>
          </Grid>
        </Grid>
        )}
        </>)}
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Regions"/></Grid>
          <Grid item xs>{((job.attrs&jobAttrExcludeRegion) > 0 ? 'Excl. ' : '') + job.regions}</Grid>
        </Grid>
        {session.role === 'admin' && !!attrs && (
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Attributes"/></Grid>
          <Grid item xs>{attrs}</Grid>
        </Grid>
        )}
        {cfg?.osv_percent && (
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="OSV Percent"/></Grid>
          <Grid item xs>{formatOsvPct(cfg.osv_percent)}</Grid>
        </Grid>
        )}
        {cfg?.ua_tpl && (
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="User Agent"/></Grid>
          <Grid item xs>
            <Typography sx={{whiteSpace: "pre-wrap"}}>{formatUATpl(cfg.ua_tpl)}</Typography>
          </Grid>
        </Grid>
        )}
        {job.extra && (
        <Grid item container xs={12}>
          <Grid item xs={3} md={2}><Label name="Extra Info"/></Grid>
          <Grid item xs>
            <Typography sx={{whiteSpace: "pre-wrap"}}>{job.extra.replaceAll('@@', '\n')}</Typography>
          </Grid>
        </Grid>
        )}
      </Grid>
    </Popup>
  );
};

export default JobState;
