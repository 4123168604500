import React from 'react';
import { Popup, EnhancedTable } from 'components';
import { trackers } from 'consts';

const formatTrackerData = (idx) => (row, _col) => {
  const cnt = row.tracker_cnt[idx];
  if (!cnt) return '--';
  const stay = Math.ceil(row.tracker_stay[idx]);
  const bounce = row.tracker_bounce[idx];
  const landingRate = (100*cnt/row.landing).toFixed(1);
  const all = [landingRate+'%', stay+'s'];
  if (bounce > 0) all.push((100*bounce/cnt).toFixed(1)+'%'); // bounce rate
  return all.join(' / ');
};

const columnSettings = Object.fromEntries(trackers.map((name, i) => [name, {label: name, format: formatTrackerData(i)}]));
const displayColumns = ['date', 'imps', 'clicks', 'ctr', 'stay', 'landing_rate', 'jump2', ...trackers];

const JobReport = ({job, onClose}) => {
  if (!job)
    return null;

  return (
    <Popup
      open={Boolean(job)}
      onClose={onClose}
      title={`${job.id} - ${job.name}`}
      styles={{width: 'auto'}}
    >
      <EnhancedTable
        stickyHeader
        styles={{width: '800px'}}
        columnSettings={columnSettings}
        displayColumns={displayColumns}
        dataUrl={`/reports?dim=jobdaily&job=${job.id}`}
        dense={true}
      />
    </Popup>
  );
};

export default JobReport;
